$(document).ready(function () {

    $(".phone").inputmask({'mask': '+7 (999) 999-99-99'});

    var thousandSeparator = function(str) {
        var parts = (str + '').split('.'),
            main = parts[0],
            len = main.length,
            output = '',
            i = len - 1;

        while(i >= 0) {
            output = main.charAt(i) + output;
            if ((len - i) % 3 === 0 && i > 0) {
                output = ' ' + output;
            }
            --i;
        }

        if (parts.length > 1) {
            output += '.' + parts[1];
        }
        return output;
    };

    $( "#slider" ).slider({
        value : 100000,//Значение, которое будет выставлено слайдеру при загрузке
        min : 0,//Минимально возможное значение на ползунке
        max : 200000,//Максимально возможное значение на ползунке
        step : 20000,//Шаг, с которым будет двигаться ползунок
        range: "min",
        create: function( event, ui ) {
            vals = $( "#slider" ).slider("value");//При создании слайдера, получаем его значение в перемен. val
            $( "#contentSlider" ).val( thousandSeparator(vals) );//Заполняем этим значением элемент с id contentSlider
            $( "#resultCash" ).html( thousandSeparator(vals/5000*150000) );
        },
        slide: function( event, ui ) {
            vals = ui.value;
            $( "#contentSlider" ).val( thousandSeparator(vals) );//При изменении значения ползунка заполняем элемент с id contentSlider
            $( "#resultCash" ).html( thousandSeparator(vals/5000*150000) );

        }
   });

    $(window).scroll(function () {
        if ($(document).scrollTop() > $("section.what_franshiza").offset().top-100) {
                $('header.header').removeClass("d-none");
        } else {
                $('header.header').addClass("d-none");
        }
    });

    $(document).on("click", ".post-form", function() {

        var root_form = $(this).parent().parent().parent();
        var phone = root_form.find(".phone").val();

        var clear_phone = phone.replace(/[^\d.]/ig, '');

        if (phone=="" || phone==undefined || clear_phone.length!=11) {
            root_form.find(".form_alert_text").hide();
            root_form.find(".form_alert_text").html("Телефон обязателен").fadeIn(1000);
        } else {

//            text = root_form.parent().find(".modal_title").html();
//            if (text == undefined) {
//                text = "Получить подробный каталог элитной недвижимости";
//            }
//            root_form.find("form").prepend('<input type="hidden" name="text" value="'+text+'">');

            var form = root_form.find("form").serialize();

//            var yaCounterID = Ya.Metrika.counters()[0].id;
//            var yaClientID = window['yaCounter'+yaCounterID].getClientID();
            yaCounterID = 0;
            yaClientID = 0;

            $.ajax({
              url: 'mail.php',
              dataType: 'json',
              data: form+"&yaCounterID="+yaCounterID+"&yaClientID="+yaClientID,
              success: function(result) {
                root_form.find(".form_alert_text").html(result.text);
                if (result.type == 'error') {
                    return(false);
                } else {
                    root_form.find("input").val("");
                }
                //yaCounter48963506.reachGoal('form', function () {return true;});
              },
              error: function(result) {
                    root_form.find(".form_alert_text").hide();
                    root_form.find(".form_alert_text").html("Ошибка отправки!").fadeIn(1000);
              }
            });

        }

    });

    $(".a-scroll-to").click(function() {
        var elementClick = $(this).attr("href");
        var destination = $(elementClick).offset().top - 25;
        $('html, body').animate({
            scrollTop: destination
        }, 1000);
    });
});